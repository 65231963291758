<template>
  <div id="app">
    <BabylonExamples />
    </div>
  </template>
  
  <script lang="ts">
  import BabylonExamples from './components/BabylonCpn.vue';
  
  export default ({
    name: 'App',
    components: {
      BabylonExamples
    }
  });
  </script>
  
  <style>
  </style>
  